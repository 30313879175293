// extracted by mini-css-extract-plugin
export var contacts__awardsCard = "bf_j6";
export var contacts__awardsCardCol = "bf_j9";
export var contacts__awardsCardLogo = "bf_j7";
export var contacts__awardsCardTitle = "bf_j8";
export var contacts__awardsCardWrapper = "bf_j5";
export var contacts__br = "bf_kb";
export var contacts__infoCard = "bf_j2";
export var contacts__infoCardBody = "bf_j4";
export var contacts__infoCardCol = "bf_j1";
export var contacts__infoCardTitle = "bf_j3";
export var contacts__infoCardWrapper = "bf_j0";
export var contacts__withOutBorderBottom = "bf_kc";